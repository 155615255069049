import {
  messageTypes,
  originalMessageTypes,
  userMessageTypes,
} from "@/utils/enums"
import { getDate } from "@/utils/settings"
import _ from "lodash"

export class MessageModel {
  constructor(data) {
    this.session_id = data.session_id || ""
    this.timestamp = data.created_at || new Date()
    this.timestamp_t = getDate(this.timestamp)
    this.lailaMessageType = data.laila_message_type || 0
    this.readyAnswers = data.buttons || []
    this.messageTypes = messageTypes.CHATBOT
    this.message = data.text.replace(/\*(.*?)\*/g, "<b>$1</b>") || ""
    this.originalMessage = data.text || ""
    this.messageWriter = false
    this.readyAnswersForReplySent = this.readyAnswers.length > 0 ? false : true
    this.attachments = data.attachments || []
    this.userMessageType = data.user_message_type || 0
    this.sharing = data.sharing || null
    this.writerSpeed = data.writer_speed
    this.intent = data.intent || ""
  }
}

export class PaginationMessageModel {
  constructor(data, allMessages) {
    this.session_id = data.session_id || ""
    this.timestamp = data.created_at || new Date()
    this.timestamp_t = getDate(this.timestamp)
    this.lailaMessageType = data.laila_message_type || 0
    this.userMessageType = data.user_message_type || 0
    this.readyAnswers = data.buttons || []
    this.messageType = data.is_laila_message
      ? messageTypes.CHATBOT
      : messageTypes.USER
    this.message = data.text || ""
    this.messageWriter = true
    this.readyAnswersForReplySent = !_.isEqual(allMessages, data)
    this.attachments = data.attachments || []
    this.sharing = data.sharing || null
    this.read = true
    this.writerSpeed = data.writer_speed
    this.intent = data.intent || ""
  }
}
